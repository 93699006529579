.svg-icon {
  display: inline-flex;
  align-self: center;
}

.svg-icon svg {
  height: 1rem;
  width: 1rem;
}

.svg-disabled svg {
  fill: gray;
}

.toast-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 40;
  position: fixed;
  top: 90px;
  right: 15px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
